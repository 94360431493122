.grad-blue-green {
    font-weight: 900;
    background-image: linear-gradient(to right, rgb(20, 184, 166), rgb(37, 99, 235));
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: ui-sans-serif, system-ui, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

.brand-home-link {
    margin-left: 10px;
    font-weight: 400;
    font-size: larger;
}